import { render, staticRenderFns } from "./delete-customer-user-prompt-modal.vue?vue&type=template&id=477b6a51&scoped=true"
import script from "./delete-customer-user-prompt-modal.vue?vue&type=script&lang=js"
export * from "./delete-customer-user-prompt-modal.vue?vue&type=script&lang=js"
import style0 from "./delete-customer-user-prompt-modal.vue?vue&type=style&index=0&id=477b6a51&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "477b6a51",
  null
  
)

export default component.exports