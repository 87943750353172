<template>
  <div class="UserManagement">
    <v-row class="UserManagement__filter-row">
      <v-col>
        <v-btn text small color="primary" @click="onAddUserModal">
          + Add User
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      class="Devices__data-table elevation-0"
      :items="customerUsers"
      :headers="headers"
      item-key="id"
      outlined
      :loading="customerUsersLoadign"
    >
      <template #item.actions="{ item }">
        <v-btn
          icon
          color="error"
          @click.stop="removeUser(item)"
          :disabled="isCurrentUser(item)"
        >
          <v-icon small> mdi-delete </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <delete-customer-user-prompt-modal
      v-model="showDeletePrompt"
      v-if="showDeletePrompt"
      :user-id="selectedUserId"
      :email="selectedUserEmail"
    />
    <add-user-modal v-model="showAddUserModal" v-if="showAddUserModal" />
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import AddUserModal from "./modals/add-user-modal/add-user-modal.vue";
import DeleteCustomerUserPromptModal from "./modals/delete-customer-user-prompt-modal/delete-customer-user-prompt-modal.vue";

const { mapGetters: customerGetters, mapActions: customerActions } =
  createNamespacedHelpers("customer");

export default {
  name: "UserManagement",
  components: {
    AddUserModal,
    DeleteCustomerUserPromptModal,
  },
  data() {
    return {
      headers: [
        { text: "Email", value: "email" },
        { text: "Priviledged", value: "is_privileged" },
        { text: "", value: "actions", sortable: false, align: "end" },
      ],
      selectedUserId: "",
      selectedUserEmail: "",
      showAddUserModal: false,
      showDeletePrompt: false,
    };
  },
  methods: {
    ...customerActions(["getCustomerUsers"]),
    removeUser({ email, id }) {
      this.selectedUserId = id;
      this.selectedUserEmail = email;
      this.showDeletePrompt = true;
    },
    isCurrentUser({ email }) {
      return email === this.user.email;
    },
    onAddUserModal() {
      this.showAddUserModal = true;
    },
  },
  watch: {
    "selectedCustomer.customer_id"() {
      this.getCustomerUsers();
    },
  },
  computed: {
    ...customerGetters({
      customer: "CUSTOMER",
      user: "USER",
      selectedCustomer: "SELECTED_CUSTOMER",
      customerUsers: "CUSTOMER_USERS",
      customerUsersLoadign: "CUSTOMER_USERS_LOADING",
    }),
  },
  mounted() {
    this.getCustomerUsers();
  },
};
</script>

<style lang="scss" scoped>
@import "./user-management";
</style>
