<template>
  <v-dialog v-model="modalOpen" max-width="315px" class="DeleteCustomerUserPromptModal">
    <v-card outlined>
      <v-card-title class="DeleteCustomerUserPromptModal__title">
        <v-row>
          <v-col cols="auto" class="mr-auto">
            Delete User
          </v-col>
          <v-col cols="auto">
            <v-btn icon @click="close">
              <v-icon small> mdi-close </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="DeleteCustomerUserPromptModal__content">
        <p>
          Are you sure you want to remove {{email}}?
        </p>
        <v-row>
          <v-col>
            <v-btn
              plain
              @click="onCancel"
              :disabled="deleteCustomerUsersLoading"
            >
              Cancel
            </v-btn>
            <v-btn
              color="error"
              @click="onDelete"
              :disabled="deleteCustomerUsersLoading"
              :loading="deleteCustomerUsersLoading"
            >
              Confirm
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapGetters: customerGetters, mapActions: customerActions } =
  createNamespacedHelpers("customer");

const { mapGetters: notificationsGetters } = createNamespacedHelpers('notifications');

export default {
  name: 'DeleteCustomerUserPromptModal',
  props: {
    userId: {
      type: [String, Number],
      required: true
    },
    email: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...customerGetters({
      deleteCustomerUsersLoading: 'DELETE_CUSTOMER_USERS_LOADING',
    }),
    ...notificationsGetters({ hasError: 'HAS_ERROR' }),
    modalOpen: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    ...customerActions(["deleteCustomerUser"]),
    onDelete() {
      this.deleteCustomerUser({ userId: this.userId });
    },
    onCancel() {
      this.close();
    },
    close() {
      this.modalOpen = false;
    }
  },
  watch: {
    deleteCustomerUsersLoading(value) {
      if (!value && !this.hasError) {
        this.close();
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import './delete-customer-user-prompt-modal';
</style>
